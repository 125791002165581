<template>
  <LayoutSuperadmin>
    <template v-slot:title>User Management</template>
    <AppInputForm
      v-model="formValid"
      v-slot="{ validate }"
      class="p-3 p-lg-5"
      @validate="addCompany"
    >
      <div class="add-company-container m-auto">
        <h3 class="text-center text-uppercase">Add Company</h3>
        <!-- Indicator -->
        <div class="add-company-container-indicator mt-5 ml-auto mr-auto">
          <AppSteps
            :activeStep="1"
            :statusList="['Company Details', 'Add Members']"
          ></AppSteps>
        </div>
        <!-- Type of Account -->
        <div class="app-input-label">TYPE OF ACCOUNT</div>
        <div class="mt-3 d-flex">
          <div
            class="m-1 p-2 d-flex align-items-center border w-50 hoverable"
            :class="{ 'border-primary': userForm.persona === 'vendor' }"
            @click="userForm.persona = 'vendor'"
          >
            <AppInputRadio
              v-model="userForm.persona"
              radioValue="vendor"
              hideError
            ></AppInputRadio>
            <div>
              <span class="d-block font-weight-5">Vendor</span>
            </div>
          </div>
          <div
            class="m-1 p-2 d-flex align-items-center border w-50 hoverable"
            :class="{ 'border-primary': userForm.persona === 'buyer' }"
            @click="userForm.persona = 'buyer'"
          >
            <AppInputRadio
              v-model="userForm.persona"
              radioValue="buyer"
              hideError
            ></AppInputRadio>
            <div>
              <span class="d-block font-weight-5">Buyer</span>
            </div>
          </div>
        </div>
        <AppDivider class="mt-3"></AppDivider>

        <!-- Company Logo -->
        <div class="mt-3 d-flex flex-column align-items-center">
          <div class="mb-1 d-block app-input-label">COMPANY LOGO</div>
          <!-- Profile Image  -->
          <div
            class="add-photo rounded-circle overflow-hidden position-relative"
          >
            <input
              ref="profile_img"
              type="file"
              accept="jpg, png"
              class="d-none"
              @input="setNewProfilePicture"
            />
            <div
              class="
                add-photo-text
                h-100
                w-100
                position-absolute
                d-flex
                align-items-center
                justify-content-center
                text-white
              "
              @click="uploadPhoto"
            >
              <AppIcon
                name="image_view"
                size="40"
                class="text-gray position-absolute"
              ></AppIcon>
            </div>
            <div
              v-if="!displayPicture"
              class="
                add-photo-placeholder
                bg-gray
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <AppIcon
                name="image_view"
                size="40"
                class="text-gray-dark-3"
              ></AppIcon>
            </div>
            <img
              v-else
              :src="displayPicture"
              width="70"
              style="aspect-ratio: 1/1"
            />
          </div>
        </div>

        <!-- First name -->
        <AppInput
          v-model="form.first_name"
          label="First Name"
          placeholder="First Name"
          class="mb-3"
          :validations="[validation.required()]"
          :validate="validate"
        ></AppInput>

        <!-- Last name -->
        <AppInput
          v-model="form.last_name"
          label="Last Name"
          placeholder="Last Name"
          class="mb-3"
          :validations="[validation.required()]"
          :validate="validate"
        ></AppInput>

        <!-- Company name -->
        <AppInput
          v-model="form.name"
          label="Company Name"
          placeholder="Company Name"
          class="mb-3"
          :validations="[validation.required()]"
          :validate="validate"
        ></AppInput>
        <!-- Company name -->
        <AppInput
          v-model="userForm.email"
          label="Company Email"
          placeholder="Company Email"
          class="mb-3"
          :validations="[validation.required()]"
          :validate="validate"
        ></AppInput>
        <!-- Contact Number -->
        <AppInput
          v-model="userForm.mobile_number"
          label="Contact Number"
          placeholder="Company Contact Number"
          class="mb-3"
          autocomplete="off"
          :validations="[validation.required(), validation.minLength(8)]"
          :validate="validate"
        ></AppInput>
        <!-- Company domain  -->
        <AppInput
          v-model="form.domain"
          label="Company Domain (Optional)"
          prepend-icon="email"
          placeholder="company.com"
          autocomplete="off"
          type="text"
          class="mb-3"
          :validations="[validation.domain('domain', optional = true)]"
          :validate="validate"
        ></AppInput>
        <!-- Password  -->
        <AppInput
          v-model="userForm.password"
          label="Password"
          placeholder="Company Password"
          autocomplete="off"
          type="password"
          class="mb-3"
          :validations="[validation.required()]"
          :validate="validate"
        ></AppInput>

        <div class="row">
          <!-- Address -->
          <div class="col-12 col-lg-6">
            <AppInput
              v-model="form.address"
              label="Address"
              placeholder="Address"
              class="mb-3"
              :validations="[validation.required()]"
            ></AppInput>
          </div>
          <!-- City -->
          <div class="col-12 col-lg-6">
            <AppInput
              v-model="form.city"
              label="City"
              placeholder="City"
              class="mb-3"
              :validations="[validation.required()]"
            ></AppInput>
          </div>
          <!-- State -->
          <div class="col-12 col-lg-6">
            <AppInputSelect
              v-model="form.state"
              label="State"
              placeholder="State"
              class="mb-3"
              :shouldSelectFirstItem="false"
              :items="states"
            ></AppInputSelect>
          </div>
          <!-- zip code -->
          <div class="col-12 col-lg-6">
            <AppInput
              v-model="form.zip_code"
              label="Zip Code"
              placeholder="Zip Code"
              class="mb-3"
              :validations="[validation.required()]"
            ></AppInput>
          </div>
        </div>

        <AppBtn class="ml-auto d-block" type="submit" :loading="loading">
          SAVE COMPANY
        </AppBtn>
        <p v-if="error" class="text-danger text-right">{{ error }}</p>
      </div>

      <SuccessModal v-model="companyAdded" @confirm="$router.back()">
        <template v-slot:description>Company added!</template>
      </SuccessModal>
    </AppInputForm>
  </LayoutSuperadmin>
</template>

<script>
import AppDivider from '@/shared/elements/AppDivider.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputRadio from '@/shared/elements/AppInputRadio.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import AppSteps from '@/shared/elements/AppSteps.vue';
import States from '@/shared/api/States';
import Company from '@/shared/api/Company';
import Users from '@/shared/api/Users';
import AppBtn from '@/shared/elements/AppBtn.vue';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import formDataHelper from '@/shared/utils/formDataHelper';
import LayoutSuperadmin from '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import validation from '@/shared/utils/validation';
import AppInputForm from '../../../shared/elements/AppInputForm.vue';

export default {
  name: 'CompanyAdd',

  components: {
    AppSteps,
    AppInputRadio,
    AppDivider,
    AppIcon,
    AppInput,
    AppInputSelect,
    AppBtn,
    SuccessModal,
    LayoutSuperadmin,
    AppInputForm,
  },

  mixins: [_appApiHelper],

  data() {
    return {
      validation,
      formValid: false,
      userForm: {
        first_name: ' ',
        last_name: 'Owner',
        email: '',
        mobile_number: '',
        company_name: '',
        get_marketing_update_email: 1,
        address: 'N/A',
        city: 'N/A',
        state: 'N/A',
        zip_code: 'N/A',
        persona: this.$route.query?.persona || 'vendor',
        password: '',
      },
      form: {
        first_name: '',
        last_name: '',
        logo: null,
        name: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        domain: '',
        persona: this.$route.query?.persona || 'vendor',
      },
      displayPicture: null,
      states: [],
      companyAdded: false,
    };
  },

  methods: {
    async getOwnerUserGroup() {
      const response = await Users.getUserGroups();
      const ownergroupName = PersonaConstants.default.group_names.find(
        (group) => group.value === 'owner'
      );
      this.userForm.groups = [
        response.data.find(
          (userGroup) => userGroup.name === ownergroupName.name
        ).id,
      ];
    },
    uploadPhoto() {
      this.$refs.profile_img.click();
    },
    setNewProfilePicture() {
      const newImg = this.$refs.profile_img.files[0];
      if (newImg.type.includes('image')) {
        this.form.logo = newImg;
        this.displayPicture = URL.createObjectURL(newImg);
      } else {
        alert('Please upload an image');
      }
    },
    async getStates() {
      const getAuth = await States.getFreeStatesAuth();
      if (!getAuth.hasError) {
        const headers = {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuth.data.auth_token}`,
        };
        const getStates = await States.getStates({ headers });
        this.states = getStates.data.map((state) => state.state_name);
        this.form.state = null;
      }
    },
    async addCompany() {
      if (!this.formValid) {
        return;
      }

      this.form.persona = this.userForm.persona;

      const companyResponse = await Company.postCompany(
        formDataHelper.generateFormData(this.form),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (companyResponse.status === 200) {
        
        const user = {
          ...this.userForm,
          profile_picture: this.form.logo,
          company_id: companyResponse.data.id,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          company: this.form.name,
          address: this.form.address,
          city: this.form.city,
          state: this.form.state,
          zip_code: this.form.zip_code,
          password_confirmation: this.userForm.password,
        };

        const userResponse = await Users.postUser(
          formDataHelper.generateFormData(user)
        );
        if (userResponse.status === 200) {
          this.companyAdded = true;
        } else {
          this.error = 'There was a problem adding the company';
        }
      } else {
        this.error = 'There was a problem adding the company';
      }
    },
  },

  mounted() {
    this.getOwnerUserGroup();
    this.getStates();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';

.add-company {
  &-container {
    max-width: 1000px;
    &-indicator {
      max-width: 500px;
    }
  }
}

.add-photo {
  cursor: pointer;
  &-text {
    display: none;
    opacity: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
  &-placeholder {
    width: 70px;
    height: 70px;
  }
  &:hover {
    .add-photo-text {
      display: block;
      opacity: 1;
    }
  }
}
</style>
